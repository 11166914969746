import { useContext, useEffect, useState } from 'react';
import useApi from '../api';
import {
	GET_FILTERS_ORGANIZATION_CHART,
	GET_FILTERS_ORGANIZATION_CHART_ERROR,
	GET_FILTERS_ORGANIZATION_CHART_SUCCESS,
	GET_LEADERS_ORGANIZATION_CHART_ERROR,
	GET_LEADERS_ORGANIZATION_CHART_SUCCESS,
	GET_ORGANIZATION_CHART,
	GET_ORGANIZATION_CHART_ERROR,
	GET_ORGANIZATION_CHART_SUCCESS,
	LOADING_OFF,
	LOADING_ON,
	POST_EMPLOYEE_IMPORT,
	POST_EMPLOYEE_IMPORT_ERROR,
	POST_EMPLOYEE_IMPORT_SUCCESS,
	PUT_ORG_CHART_LEADERS,
	PUT_ORG_CHART_LEADERS_ERROR,
	PUT_ORG_CHART_LEADERS_SUCCESS,
} from '../../business/constants';
import { Toast } from '../../components/toast';
import { generateUrlBase } from '../../utils/utils';
import { StoreContext } from '../../business/Provider';
import { useIntegrations } from '../integrations/useIntegrations';

export const useOrganizationChart = () => {
	const {
		dispatchGetOrganizationChart,
		dispatchLoading,
		getCompanySettings,
		getOrgChartContextState,
		dispatchGetLeadersOrgChart,
		dispatchPutLeadersOrganizationChart,
		dispatchPostImportEmployee,
	} = useContext(StoreContext);
	const { attendanceImportDetail } = getCompanySettings?.companies;
	const [isImported, setIsImported] = useState(false);
	const {
		stateGetOrgChart,
		getOrgChartSuccess_DP,
		getOrgChartInit_DP,
		getOrgChartError_DP,
	} = getOrgChartContextState;

	const { postIntegrations } = useIntegrations();

	const getIsPayroll = JSON.parse(sessionStorage.getItem('isPayrollCall'));
	const isPayRoll = getIsPayroll ? 30 : 10;

	const { genericApiCall } = useApi();

	const getOrganizationChartData = async (_data) => {
		try {
			const baseUrl = generateUrlBase(
				'org-chart/employees?filters=' + _data + '&origin=' + isPayRoll
			);

			dispatchGetOrganizationChart({ type: GET_ORGANIZATION_CHART });
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const data = result.data.results;

			if (result.status === 200) {
				dispatchGetOrganizationChart({
					type: GET_ORGANIZATION_CHART_SUCCESS,
					payload: data,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetOrganizationChart({
					type: GET_ORGANIZATION_CHART_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast(
				'warning',
				'Se encontró un error intente mas tarde.',
				error.message,
				error
			);
			dispatchGetOrganizationChart({ type: GET_ORGANIZATION_CHART_ERROR });

			dispatchLoading({ type: LOADING_OFF });
		}
	};
	const getLeadersOrganizationChartData = async (companyId) => {
		try {
			dispatchLoading({ type: LOADING_ON });
			const baseUrl = generateUrlBase(
				'org-chart/leaders?origin=' + isPayRoll + '&companyId=' + companyId
			);

			dispatchGetLeadersOrgChart({
				type: GET_LEADERS_ORGANIZATION_CHART_ERROR,
			});
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const data = result.data.results;

			if (result.status === 200) {
				dispatchGetLeadersOrgChart({
					type: GET_LEADERS_ORGANIZATION_CHART_SUCCESS,
					payload: data,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetLeadersOrgChart({
					type: GET_LEADERS_ORGANIZATION_CHART_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast(
				'warning',
				'Se encontró un error intente mas tarde.',
				error.message,
				error
			);
			dispatchGetLeadersOrgChart({
				type: GET_LEADERS_ORGANIZATION_CHART_ERROR,
			});

			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const putLeadersAssignment = async (data, dataToBase64) => {
		const baseUrl = generateUrlBase(`org-chart/leaders`);
		dispatchPutLeadersOrganizationChart({
			type: PUT_ORG_CHART_LEADERS,
		});
		dispatchLoading({ type: LOADING_ON });
		console.log('putLeadersAssignment', data);
		try {
			//return setTimeout(() => {
			const result = await genericApiCall(baseUrl, 'PUT', data, {}, {});
			if (result.status === 200) {
				const collaboratorData = result.data;
				dispatchPutLeadersOrganizationChart({
					type: PUT_ORG_CHART_LEADERS_SUCCESS,
					payload: collaboratorData,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchPutLeadersOrganizationChart({
					type: PUT_ORG_CHART_LEADERS_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			//	}, 1000);
			Toast('success', 'Líder asignado correctamente', '', '');
			return getOrganizationChartData(dataToBase64);
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchPutLeadersOrganizationChart({
				type: PUT_ORG_CHART_LEADERS_ERROR,
			});
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const dataFilterOrganizationChart = async (value = false) => {
		try {
			const baseUrl = generateUrlBase('org-chart/filters?invited=' + value);
			getOrgChartInit_DP();
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const data = result.data.data;
			if (result.status === 200) {
				getOrgChartSuccess_DP(data);
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				getOrgChartError_DP(message);
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast(
				'warning',
				'Se encontró un error intente mas tarde.',
				error.message,
				error
			);
			getOrgChartError_DP();
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const postImportEmployeesFunction = async () => {
		const baseUrl = generateUrlBase(`company/employees/import`);
		dispatchPostImportEmployee({
			type: POST_EMPLOYEE_IMPORT,
		});
		dispatchLoading({ type: LOADING_ON });

		try {
			//return setTimeout(() => {
			const result = await genericApiCall(baseUrl, 'POST', {}, {}, {});
			if (result.status === 200) {
				const collaboratorData = result.data;
				dispatchPostImportEmployee({
					type: POST_EMPLOYEE_IMPORT_SUCCESS,
					payload: collaboratorData,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchPostImportEmployee({
					type: POST_EMPLOYEE_IMPORT_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			//	}, 1000);
			//Toast('success', 'Información importada con éxito', '', '');
			return dataFilterOrganizationChart();
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchPostImportEmployee({
				type: POST_EMPLOYEE_IMPORT_ERROR,
			});
			dispatchLoading({ type: LOADING_OFF });
		}
	};
	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = JSON.parse(attendanceImportDetail);
				if (
					data.Employees === undefined ||
					data.Employees === null ||
					!data.Employees
				) {
					await postIntegrations(203);
				}
				if (data.OrgChart !== undefined && data.OrgChart) {
					await dataFilterOrganizationChart();
				} else {
					await postIntegrations(206).finally(() => {
						setIsImported(true);
						return dataFilterOrganizationChart();
					});
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		if (!isImported) {
			fetchData();
		} else {
			if (stateGetOrgChart.isLoad) dataFilterOrganizationChart();
		}
		// dataFilterOrganizationChart();
	}, []);

	return {
		getOrganizationChartData,
		dataFilterOrganizationChart,
		getLeadersOrganizationChartData,
		putLeadersAssignment,
	};
};
