import { createContext, useContext, useEffect, useState } from 'react';
import useReload from '../../hooks/useReload';
import useInvitations from '../../hooks/invitations/useInvitations';
import { StoreContext } from '../../business/Provider';
import {
	tableArrayStateEmployeeOptions,
	tableArrayStateOptions,
} from '../../screens/Invitations/const';
import { FilterMatchMode } from 'primereact/api';

import {
	AlertToastInfoNames,
	GeneralNames,
	InvitationsScreenNames,
} from '../../namesConstants/names';
import { Toast } from '../../components/toast';
import {
	filterDataInvitations,
	toExcelExport,
} from '../../screens/Invitations/utils';

export const InvitationsContext = createContext();

export const InvitationsServiceManagerProvider = ({ children }) => {
	const {
		getInvitationsState,
		loadingState,
		companyState,
		dispatchLoading,
		verifyAvailableLicenseState,
		sendUnInviteState,
	} = useContext(StoreContext);

	const optionsOfFilters = tableArrayStateOptions;
	const employeeOptionsFilter = tableArrayStateEmployeeOptions;
	const initialStateFilterOption = {
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	};
	const [dialogContent, setDialogContent] = useState('');
	const [endDate, setEndDate] = useState(null);
	const [valueProgress, setValueProgress] = useState(0);
	//const [optionsOfFilters] = useState(tableArrayStateOptions);
	//const [employee] = useState(tableArrayStateEmployeeOptions);
	const [filters, setFilters] = useState(initialStateFilterOption);
	const [filtersList, setFiltersList] = useState([]);
	const [invitationsData, setInvitationsData] = useState([]);
	const [inputTextSearchValue, setInputTextSearchValue] = useState('');
	const [individualRow, setIndividualRow] = useState({});
	const [isIndividual, setIsIndividual] = useState(true);
	const [openDialog, setOpenDialog] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [collaboratorsList, setCollaboratorsList] = useState([]);
	const [showProgressBarDialog, setShowProgressBarDialog] = useState(false);
	const [statusValueSelected, setStatusValueSelected] = useState('all');
	const [lengthUsersSend, setLengthUsersSend] = useState(0);

	const [selectFilterEmployee, setSelectFilterEmployee] = useState('0');
	const [showCloseDialog, setShowCloseDialog] = useState(false);
	const [sort, setSort] = useState([{ field: 'code', order: -1 }]);
	const [startDate, setStartDate] = useState(null);
	const [toSend, setToSend] = useState([]);
	const [typeInvitation, setTypeInvitation] = useState('invitation');
	const {
		getInvitations,
		sendInvitation,
		sendInvitationsInParallel,
		sendUnInvite,
		verifyAvailableLicense,
		sendUnInviteParallel,
	} = useInvitations(
		updateProgressBar,
		setShowProgressBarDialog,
		validateErrorsInData
	);

	useReload([getInvitations]);

	useEffect(() => {
		if (getInvitationsState.invitations !== undefined) {
			setInvitationsData(getInvitationsState.invitations);
		}
	}, [getInvitationsState.invitations]);

	useEffect(() => {
		if (sendUnInviteState.invitations !== undefined) {
			// Realizar el merge
			const responseData = sendUnInviteState.invitations.data;
			const mergedData = responseData
				.filter((id) => toSend.some((item) => item.id === id)) // Filtra los IDs que no están en toSend
				.map((id) => toSend.find((item) => item.id === id));
			if (mergedData.length > 0) {
				setOpenModal(true);
				setCollaboratorsList(mergedData);
				setToSend([]);
			}
		}
	}, [sendUnInviteState.invitations]);

	useEffect(() => {
		filterData(
			invitationsData,
			statusValueSelected,
			selectFilterEmployee,
			startDate,
			endDate
		);
	}, [
		invitationsData,
		statusValueSelected,
		selectFilterEmployee,
		startDate,
		endDate,
	]);

	function handleInputTextSearch(e) {
		const { value } = e.target;
		const newValue = value.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setFilters(_filters);
		setInputTextSearchValue(newValue);
	}

	function filterData(
		data,
		filterStatus,
		filterEmployeeStatus,
		startDate,
		endDate
	) {
		const _data = filterDataInvitations(
			data,
			filterStatus,
			filterEmployeeStatus,
			startDate,
			endDate
		);
		setFiltersList(_data);
	}

	const handleFilterValueSelected = (e) => {
		const value = e.target.value;
		setStatusValueSelected(value);
	};

	const handleValueStatusEmployee = (e) => {
		const value = e.target.value;
		setSelectFilterEmployee(value);
	};

	function clearTextLabel() {
		setInputTextSearchValue('');
		setFilters(initialStateFilterOption);
	}

	function handleStartDate(e) {
		setStartDate(e.target.value);
	}

	function handleEndDate(e) {
		setEndDate(e.target.value);
	}

	const iconsData = [
		{ icon: 'send', title: 'Enviar Invitaciones', onClick: acceptFunction },
		{
			icon: 'dismiss',
			title: 'Anular Invitaciones',
			onClick: dismissFunction,
		},
		{ icon: 'clear', title: 'Limpiar Filtros', onClick: clearFilters },
		{
			icon: 'excel',
			title: GeneralNames.GeneralExportToExcel,
			onClick: toExcelExportFunction,
		},
	];

	async function toExcelExportFunction() {
		return await toExcelExport(filtersList, dispatchLoading, companyState);
	}

	function dismissFunction() {
		return toSend.length === 0
			? Toast(
					'warning',
					InvitationsScreenNames.AlertValidationEmptyArrayTitle,
					InvitationsScreenNames.AlertValidationEmptyArraySubTitle,
					'success'
			  )
			: sendUnInviteFunctionActionMultiple();
	}

	const sendUnInviteFunctionActionMultiple = () => {
		setOpenDialog(true);
		setTypeInvitation('un-invite');
		setIsIndividual(false);
	};

	function clearFilters() {
		setStatusValueSelected('all');
		setSelectFilterEmployee('0');
		setStartDate(null);
		setEndDate(null);
		setInputTextSearchValue('');
		setFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		});
	}

	function acceptFunction() {
		return toSend.length === 0
			? Toast(
					'warning',
					InvitationsScreenNames.AlertValidationEmptyArrayTitle,
					InvitationsScreenNames.AlertValidationEmptyArraySubTitle,
					'success'
			  )
			: returnRenderDialog();
	}

	function returnRenderDialog() {
		setOpenDialog(true);
		setIsIndividual(false);
		setTypeInvitation('invitation');
	}

	const sendUnInviteFunctionAction = (rowData) => {
		setOpenDialog(true);
		setIndividualRow(rowData);
		setTypeInvitation('un-invite');
		setIsIndividual(true);
	};

	const sendInvitationFunctionAction = (rowData) => {
		setOpenDialog(true);
		setIndividualRow(rowData);
		setTypeInvitation('invitation');
		setIsIndividual(true);
	};
	async function sendInvitationsAction() {
		const filteredToSend = toSend.filter(
			(item) => item.invitationStatus !== 2 && item.email !== null
		);
		if (filteredToSend.length === 0 && isIndividual === false) {
			Toast(
				'warning',
				'Las invitaciones no pudieron ser enviadas',
				'Las invitaciones seleccionadas ya cuentan con una invitación activa o no cuentan con correo válido',
				'success'
			);
		} else {
			const dataToSend =
				filteredToSend.length > 0 && isIndividual === false
					? filteredToSend
					: [individualRow];
			const newDataToSend = dataToSend.map((item) => {
				return {
					id: item.id,
					email: item.email,
					name: item.name,
					firstLastName: item.firstLastName,
					secondLastName: item.secondLastName,
					invitationStatus: item.invitationStatus,
				};
			});
			return await verifyAvailableLicense(newDataToSend).finally(() => {
				if (verifyAvailableLicenseState.availableLicense !== null) {
					const sendInvitation = async () => {
						const { data } = verifyAvailableLicenseState.availableLicense;
						await sendInvitationAction(data);
					};

					return sendInvitation();
				}
			});
		}
	}

	async function sendInvitationAction(verifyAvailable) {
		const filteredToSend = toSend.filter(
			(item) => item.invitationStatus !== 2 && item.email !== null
		);
		const dataToSend =
			filteredToSend.length > 0 && isIndividual === false
				? filteredToSend
				: [individualRow];
		const newDataToSend = dataToSend.map((item) => {
			return {
				id: item.id,
				email: item.email,
				name: item.name,
				firstLastName: item.firstLastName,
				secondLastName: item.secondLastName,
				invitationStatus: item.invitationStatus,
			};
		});

		if (
			(verifyAvailable !== undefined &&
				verifyAvailable !== null &&
				verifyAvailable >= newDataToSend.length &&
				verifyAvailable !== 0) ||
			verifyAvailable === -1
		) {
			if (!isIndividual) setShowProgressBarDialog(true);

			setLengthUsersSend(newDataToSend.length);
			return isIndividual
				? await sendInvitation(newDataToSend)
				: await sendInvitationsInParallel(newDataToSend);
		} else {
			Toast(
				'warning',
				'No hay licencias disponibles',
				AlertToastInfoNames.AlertLicenseAvailable,
				'success'
			);
		}
	}

	function validateErrorsInData(dataErrors) {
		const errorInvitations = dataErrors;

		const collaboratorsAlreadyAccepted = toSend.filter(
			(item) => item.invitationStatus === 2
		);
		collaboratorsAlreadyAccepted.forEach((collaborator) => {
			collaborator.error = 'Ya cuenta con una invitación aceptada';
		});
		const collaboratorsUnavailable = toSend.filter(
			(item) => item.email === null
		);
		collaboratorsUnavailable.forEach((collaborator) => {
			collaborator.error = 'El colaborador no cuenta con correo válido';
		});
		setCollaboratorsList([
			...collaboratorsAlreadyAccepted,
			...collaboratorsUnavailable,
		]);
		const listCollaborators = [
			...collaboratorsAlreadyAccepted,
			...collaboratorsUnavailable,
		];
		if (errorInvitations.length > 0) {
			Toast('warning', AlertToastInfoNames.sendInvitationWarning, '', '');
			try {
				// Determina si es individual o múltiple y crea un array correspondiente
				const _dataMap = isIndividual ? [individualRow] : toSend;

				// Filtra los datos para incluir solo aquellos que tienen coincidencia en errorInvitations
				const mergeData = _dataMap.filter((item) => {
					return errorInvitations.some(
						(jsonItem) => jsonItem.email === item.email
					);
				});

				// Mapea los datos filtrados y añade la propiedad error desde errorInvitations
				const _mergeData = mergeData.map((item) => ({
					...item,
					error: errorInvitations.find(
						(jsonItem) => jsonItem.email === item.email
					)?.error,
				}));

				// Actualiza colaboradoresList con los nuevos datos
				setCollaboratorsList((prevList) => [...prevList, ..._mergeData]);
				setToSend([]);
			} catch (error) {
				console.error('Error al analizar la cadena JSON:', error);
			}
		}
		if (listCollaborators.length > 0 || errorInvitations.length > 0) {
			setOpenModal(true);
		}
	}

	const sendUnInviteAction = async () => {
		try {
			const dataToSend =
				toSend.length > 0 ? toSend.map((item) => item.id) : [individualRow.id];
			return await sendUnInvite(dataToSend);
		} catch (error) {
			console.log(error);
		}
	};

	function handleModalClose() {
		// Realizar acciones después de cerrar el modal
		setToSend([]);
		setTypeInvitation('');
		setCollaboratorsList([]);
		setLengthUsersSend(0);
	}

	function updateProgressBar(progress) {
		setValueProgress(progress);
	}

	return (
		<InvitationsContext.Provider
			value={{
				inputTextSearchValue,
				handleInputTextSearch,
				iconsData,
				optionsOfFilters,
				employeeOptionsFilter,
				handleFilterValueSelected,
				handleValueStatusEmployee,
				statusValueSelected,
				startDate,
				handleStartDate,
				endDate,
				handleEndDate,
				clearTextLabel,
				filters,
				sendUnInviteFunctionAction,
				sendInvitationFunctionAction,
				showCloseDialog,
				setShowCloseDialog,
				dialogContent,
				setDialogContent,
				toSend,
				setToSend,
				sort,
				setSort,
				filtersList,
				selectFilterEmployee,
				loadingState,
				typeInvitation,
				openModal,
				setOpenModal,
				sendInvitationsAction,
				collaboratorsList,
				sendUnInviteAction,
				handleModalClose,
				openDialog,
				setOpenDialog,
				updateProgressBar,
				valueProgress,
				showProgressBarDialog,
				lengthUsersSend,
			}}
			displayName='Invitations'>
			{children}
		</InvitationsContext.Provider>
	);
};
