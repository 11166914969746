import { useReducer } from 'react';
import { getFiltersOrganizationChartRed } from '../../../business/reducers/organizationChart/getFiltersOrganizationCharRed';
import { getFiltersOrganizationChartIS } from '../../../business/initialState/organizationChart/getFiltersOrganizationChartIS';
import {
	GET_FILTERS_ORGANIZATION_CHART,
	GET_FILTERS_ORGANIZATION_CHART_ERROR,
	GET_FILTERS_ORGANIZATION_CHART_SUCCESS,
} from '../../../business/constants';

export const useGetOrganizationChart = () => {
	const [stateGetOrgChart, dispatch] = useReducer(
		getFiltersOrganizationChartRed,
		getFiltersOrganizationChartIS
	);

	const getOrgChartInit_DP = () => {
		dispatch({
			type: GET_FILTERS_ORGANIZATION_CHART,
		});
	};
	const getOrgChartSuccess_DP = (data) => {
		dispatch({
			type: GET_FILTERS_ORGANIZATION_CHART_SUCCESS,
			payload: data,
		});
	};
	const getOrgChartError_DP = (data = '') => {
		dispatch({
			type: GET_FILTERS_ORGANIZATION_CHART_ERROR,
			payload: data,
		});
	};

	return {
		stateGetOrgChart,
		getOrgChartInit_DP,
		getOrgChartSuccess_DP,
		getOrgChartError_DP,
	};
};
